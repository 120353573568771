<template>
  <div class="workOrderConsumable">
    <div class="search">
      <v-search v-model="keyWords" @onSearch="onSearch"></v-search>
    </div>
    <div class="content">
      <div
        :class="['list', { active: curIndex == item }]"
        v-for="item in 10"
        :key="item"
        @click="curIndex = item"
      >
        <div class="item">
          <div class="item-l">名称</div>
          <div class="item-r">三号锂基脂以（250g）已采购</div>
        </div>
        <div class="item">
          <div class="item-l">计量单位</div>
          <div class="item-r unit">10包</div>
        </div>
        <div class="item">
          <div class="item-l">使用数量</div>
          <div class="item-r">
            <v-stepper :min="1" v-model="num"></v-stepper>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="confirm-btn">确认</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "workOrderConsumable",
  components: {},
  props: {},
  data() {
    return {
      curIndex: 0,
      keyWords: "",
      num: "",
    };
  },
  created() {},
  mounted() {},
  methods: {
    onSearch(value) {
    },
  },
};
</script>

<style scoped lang="less">
.workOrderConsumable {
  width: 100%;
  min-height: 100%;
  background: #f8f8f8;
  .search {
    z-index: 9;
    width: 100%;
    height: 112px;
    background: #ffffff;
    position: sticky;
    top: 0;
    left: 0;
    ::v-deep .v-search {
      height: 100%;
      .van-search {
        height: 100%;
        padding: 22px 48px 22px 40px;
        .van-search__content {
          border-radius: 1000px;
        }
        .van-search__action {
          font-size: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #fe4416;
          line-height: 40px;
        }
      }
    }
  }
  .content {
    box-sizing: border-box;
    padding: 20px 30px 225px 30px;
    .list {
      min-height: 264px;
      background: #ffffff;
      border-radius: 16px;
      box-sizing: border-box;
      padding: 20px 28px 26px 40px;
      display: flex;
      flex-direction: column;
      border: 2px solid transparent;
      justify-content: space-between;
      &:not(:last-child) {
        margin-bottom: 20px;
      }
      &.active {
        border: 2px solid #fe4416;
        box-sizing: border-box;
      }
      .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        .item-l {
          width: 120px;
          flex-shrink: 0;
          font-size: 28px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: rgba(51, 51, 51, 0.85);
          line-height: 56px;
        }
        .item-r {
          box-sizing: border-box;
          font-size: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden; //溢出内容隐藏
          text-overflow: ellipsis; //文本溢出部分用省略号表示
          display: -webkit-box; //特别显示模式
          -webkit-line-clamp: 2; //行数
          line-clamp: 2;
          -webkit-box-orient: vertical; //盒子中内容竖直排列

          color: rgba(51, 51, 51, 0.85);
          line-height: 56px;
          ::v-deep .van-stepper {
            .van-stepper__minus {
              width: 46px;
              height: 50px;
              background: #f3f6f8;
              border-radius: 12px 0px 0px 12px;
              border: 2px solid #d8d8d8;
              margin-right: -2px;
              box-sizing: border-box;
            }
            .van-stepper__input {
              margin: 0;
              width: 98px;
              height: 50px;
              background: #ffffff;
              border-top: 2px solid #d8d8d8;
              border-bottom: 2px solid #d8d8d8;
              box-sizing: border-box;
            }
            .van-stepper__plus {
              width: 46px;
              height: 50px;
              background: #f3f6f8;
              border-radius: 0px 12px 12px 0;
              margin-left: -2px;
              border: 2px solid #d8d8d8;
              box-sizing: border-box;
            }
          }
        }
        .unit {
          font-size: 28px;
        }
      }
    }
  }
  .footer {
    width: 100%;
    z-index: 10;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    box-sizing: border-box;
    background: #ffffff;
    padding: 48px 36px 90px 0;
    .confirm-btn {
      text-align: center;
      width: 226px;
      height: 74px;
      background: #fe4416;
      border-radius: 44px;
      font-size: 30px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 74px;
    }
  }
}
</style>
